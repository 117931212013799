<template lang="pug">
  div.main-content.layout-main__centering.container.pb-5.mt-5
    .login-window(v-loading="isLoading")
      h3.login-window__title Сбосить пароль
      .login-window-container
        .register__information(v-if='warnings')
          el-alert(
            title="Ошибка смены пароля",
            type="error",
            show-icon,
            :closable="false",
          )
            div.errors_container(slot="default")
              div(v-for="(errors) in warnings")
                div(v-for="(error) in errors") {{ error }}
        .register__information(v-if="success")
          el-alert(
            title="Успешно",
            description="Пароль успешно изменен, авторизуйтесь используя новый пароль"
            type="success",
            :closable="false",
            show-icon
          )
          .divider
          el-alert(
            title="",
            :description="`Через ${closeTimer} ${seconds} Вы будете перенаправлены на маркет`",
            type="warning",
            :closable="false",
          )
        form.register__form(v-else, @submit.prevent='signup')
          .register__column
            .form-group
              label(for='email') Email:
              input#email.form-control(
                v-model.trim='email',
                type='email',
                placeholder='Ваш Email',
                autocomplete='off',
                required=''
              )
            .form-group
              label(for='password')
                | Пароль:
              input#password.form-control(
                ref="passInput",
                v-popover:popover,
                v-model.trim='password',
                type='password',
                placeholder='Ваш пароль',
                autocomplete='off',
                required=''
              )
              el-popover(
                ref="popover"
                placement="right",
                width="200"
                trigger="focus",
                :title="passPopup.title",
              )
                span(v-for="condition in passPopup.content")
                  i.el-icon-success(v-if="similar[condition.type]", style="color: green")
                  i.el-icon-warning(v-else, style="color: orange")
                  | {{ condition.text }}
                  br
            .form-group
              label(for='passwordConfirm') Повторите пароль:
              small.password-tip.text-success(v-if="similar.same") Пароли совпадают
              small.password-tip.text-danger(v-if="similar.different") Пароли не совпадают
              input#passwordConfirm.form-control(
                v-model.trim='passwordConfirm',
                type='password',
                placeholder='Ваш пароль еще раз',
                autocomplete='off',
                required='',
              )
            .base-field__outer
              button.btn.btn-success.w-100.btn-finex.mt-3(type='submit') Сменить пароль
</template>

<script>
  import utilsMixin from '../components/mixins/utils';
  import {mapGetters} from 'vuex'
  import {RESET_PASSWORD} from '../store/actions/auth'
  import passwordMixin from '../components/mixins/passport';

  export default {
    mixins: [passwordMixin, utilsMixin],
    data() {
      return {
        email: '',
        success: false,
        closeTimer: 3,
        interval: null,
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'loadingRequest',
        'passTitle',
        'passContent',
      ]),
      isLoading() {
        return this.loading(RESET_PASSWORD) === 'loading';
      },
      requestData() {
        let req = this.loadingRequest(RESET_PASSWORD);
        return (req && req.response ? req.response : {});
      },
      warnings() {
        if (this.loading(RESET_PASSWORD) === 'error') {
          return this.requestData.data ? this.requestData.data.errors : false;
        }
        return false;
      },
      passPopup() {
        return {title: this.passTitle[this.passType.text], content: this.passContent[this.passType.text]};
      },
      token(){
        return this.$route.params.token || '';
      },
      seconds(){
        return this.noun(this.closeTimer, 'секунду', 'секунды', 'секунд')
      },
    },
    watch: {
      password: 'checkPassword',
      passwordConfirm: 'checkPassword',
    },
    methods: {
      signup() {
        if(this.similar.same && this.passType.confirm){
          this.$store.dispatch(RESET_PASSWORD, {
            token: this.token,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirm,
          }).then(() => {
            this.$set(this, 'success', true);
            this.interval = setInterval(() => {
              this.closeTimer -= 1;
              if(this.closeTimer === 0){
                this.$router.push({ name: 'Login' });
                this.$set(this, 'success', false);
                this.$set(this, 'email', '');
                this.$set(this, 'password', '');
                this.$set(this, 'passwordConfirm', '');
                clearInterval(this.interval);
              }
            }, 1000);
          });
        }else{
          this.$refs.passInput.focus()
        }
      },
    },
  }
</script>

<style lang="scss"></style>
